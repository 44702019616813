import React from 'react';
import { useIntl } from 'react-intl';

import { FEATURES_DATA } from 'constants/integrations';

import Feature from './components/Feature/Feature';

import CloudVector from 'assets/images/cloud-up.svg';
import BostaLogoLanding from 'assets/images/bosta-logo-landing.svg';

import './Features.less';

const Features = () => {
  const { formatMessage } = useIntl();

  const scrollToSignUp = () => {
    const element = document.getElementById('SignUpForm');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="br-features-container">
      <img src={CloudVector} alt="cloud" className="br-features-cloud" />
      <img src={BostaLogoLanding} alt="bosta" className="br-features-logo" />
      <img src={CloudVector} alt="cloud" className="br-features-cloud-bottom" />
      <div className="br-features-text">
        <h1>
          {formatMessage({ id: 'bosta_integration_landing.features.title' })}
        </h1>
      </div>

      <div className="br-features-list">
        {FEATURES_DATA.map((feature, index) => (
          <Feature key={index} feature={feature} />
        ))}
      </div>

      <div className="br-features-sign-up">
        <p>
          {formatMessage({ id: 'bosta_integration_landing.features.explore' })}
        </p>
        <button onClick={scrollToSignUp} className="br-feature-sign-up-button">
          {formatMessage({ id: 'bosta_integration_landing.hero.signup' })}
        </button>
      </div>
    </div>
  );
};

export default Features;
