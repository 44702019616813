import React from 'react';
import { useIntl } from 'react-intl';
import { Steps } from 'antd';

import { STEPS_LANDING } from 'constants/integrations';

import BostaLogoLanding from 'assets/images/bosta-logo-landing.svg';
import bostaCarsImage from 'assets/images/br-integrations-steps.svg';

import './Steps.less';

const StepsComponent = () => {
  const { formatMessage } = useIntl();

  return (
    <div className="br-steps-container">
      <img src={BostaLogoLanding} alt="bosta" className="br-steps-logo" />
      <img src={bostaCarsImage} alt="bosta" className="br-steps-car" />

      <h1>{formatMessage({ id: 'bosta_integration_landing.steps.title' })}</h1>
      <p>{formatMessage({ id: 'bosta_integration_landing.steps.desc' })}</p>

      <div className="br-steps-line-combined">
        <div className="br-steps-line-connector"></div>
        {[1, 2, 3].map((step) => (
          <div key={step} className="br-steps-line">
            <div className="br-steps-line-step">
              <span>{step}</span>
            </div>
            <div className="br-steps-line-title">
              <span>
                {formatMessage({
                  id: `bosta_integration_landing.steps.step${step}.title`
                })}
              </span>
            </div>
          </div>
        ))}
      </div>

      <Steps
        className="br-steps-line_vertical"
        current={3}
        labelPlacement="vertical"
        items={STEPS_LANDING}
      />
    </div>
  );
};

export default StepsComponent;
