import About from 'components/About/About';
import Mobile from 'components/Mobile/Mobile';
import UseCases from 'components/UseCases/UseCases';
import Careers from 'components/Careers/Careers';
import ContactSales from 'components/ContactSales/ContactSales';
import MergedIntegrations from 'components/MergedIntegrations/MergedIntegrations';
import Solutions from 'components/Solutions/Solutions';
import Pricing from 'components/Pricing/Pricing';
import Dashboard from 'components/Dashboard/Dashboard';
import ShipmentTracking from 'components/ShipmentTracking/ShipmentTracking';
import FAQ from 'components/FAQ/FAQ';
import SME from 'components/SME/SME';
import BostaClinic from 'components/BostaClinic/BostaClinic';
import PrivacyPolicy from 'components/PrivacyPolicy/PrivacyPolicy';
import AdminLogin from 'components/AdminLogin/AdminLogin';
import Blog from 'components/Blog/Blog';
import BlogContentManager from '../components/BlogContentManager/BlogContentManager';
import Article from 'components/Blog/components/Article/Article';
import CreateEditArticle from '../components/BlogContentManager/components/CreateEditArticle/CreateEditArticle';
import BostaInsights from 'components/BostaInsights/BostaInsights';
import { isBostaInsightsAvailable } from 'constants/countries/countries-mapping';
import Report from 'components/BostaInsights/components/Report/Report';
import InternationalShipping from 'components/InternationalShipping/InternationalShipping';
import BostaCapital from 'components/BostaCapital/BostaCapital';
import ThankYou from 'components/BostaCapital/Components/Thankyou/Thankyou';
import BostaFulfillment from 'components/BostaFulfillment/BostaFulfillment';
import Integrations from 'components/Landing/Integrations';
const routes = [
  {
    path: '/about',
    key: 'About',
    component: About
  },
  {
    path: '/mobile',
    key: 'Mobile',
    component: Mobile
  },
  {
    path: '/bosta-capital/thank-you',
    key: 'Thank You',
    component: ThankYou
  },
  {
    path: '/bosta-capital',
    key: 'Bosta Capital',
    component: BostaCapital
  },
  {
    path: '/business',
    key: 'Use Cases',
    component: UseCases
  },
  {
    path: '/careers',
    key: 'Careers',
    component: Careers
  },
  {
    path: '/contact-shipping-sales',
    // exact: true,
    key: 'Contact Sales',
    component: ContactSales
  },
  {
    path: '/merged-integrations',
    // exact: true,
    key: 'Merged Integrations',
    component: MergedIntegrations
  },
  {
    path: '/solutions',
    key: 'Solutions',
    component: Solutions
  },
  {
    path: '/tracking-shipments',
    key: 'Tracking Shipments',
    component: ShipmentTracking
  },
  {
    path: '/tracking-shipment',
    key: 'Tracking Shipments',
    component: ShipmentTracking
  },
  {
    path: '/pricing',
    key: 'Pricing',
    component: Pricing
  },
  {
    path: '/faq',
    key: 'Faq',
    component: FAQ
  },
  {
    path: '/smes',
    key: 'Sme',
    component: SME
  },
  {
    path: '/dashboard',
    key: 'Dashboard',
    component: Dashboard
  },
  {
    path: '/bosta-clinic',
    key: 'BostaClinc',
    component: BostaClinic
  },
  {
    path: '/privacy-policy',
    key: 'Bosta privacy policy',
    component: PrivacyPolicy
  },
  {
    path: '/fulfillment',
    key: 'Bosta Fulfillment',
    component: BostaFulfillment
  },
  {
    path: '/login',
    key: 'Admin Login',
    component: AdminLogin
  },
  {
    path: '/blog',
    exact: true,
    key: 'Blog',
    component: Blog
  },
  {
    path: '/blogs-cms',
    requireAuth: true,
    key: 'CMS',
    component: BlogContentManager
  },
  {
    path: '/create-article',
    requireAuth: true,
    key: 'create article',
    component: CreateEditArticle
  },
  {
    path: '/blog/:id',
    // requireAuth: true,
    key: 'CMS',
    component: Article
  },
  {
    path: '/insights',
    key: 'BostaInsights',
    exact: true,
    component: BostaInsights,
    haveAccess: isBostaInsightsAvailable()
  },
  {
    path: '/insights/:id',
    key: 'BostaInsights',
    component: Report,
    haveAccess: isBostaInsightsAvailable()
  },
  {
    path: '/international-express',
    key: 'InternationalShipping',
    exact: true,
    component: InternationalShipping
  },
  {
    path: '/integrations',
    key: 'Integrations',
    component: Integrations
  }
];

export default routes;
