import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import './Banner.less';

const Banner = () => {
  const { formatMessage, locale } = useIntl();
  const [visible, setVisible] = useState(true);
  const closeBanner = () => {
    setVisible(false);
  };

  if (!visible) return null;

  return (
    <div className="br-banner-integrations">
      <span className="br-banner-text">
        <span className='br-banner-emoji'>{formatMessage({ id: 'bosta_integration_landing.banner.emoji' })}</span>
        {formatMessage({ id: 'bosta_integration_landing.banner.text1' })}{' '}
        <span>
          {formatMessage({ id: 'bosta_integration_landing.banner.span' })}
        </span>{' '}
        {formatMessage({ id: 'bosta_integration_landing.banner.text2' })}
      </span>
      <button className="br-banner-close" onClick={closeBanner}>
        ×
      </button>
    </div>
  );
};

export default Banner;
