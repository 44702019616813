import React, { useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Form, Input, Radio, Select } from 'antd';

import { integrationsFormSubmit } from 'services/integrations';
import { ALL_COUNTRIES } from 'constants/countries/constants/countries';
import { emailRule } from 'utils/forms';
import { AVERAGE_MONTHLY_ORDERS, INTEGRATIONS_DATA } from 'constants/integrations';

import BRButton from 'components/BRButton/BRButton';
import BRInternationalPhone from 'components/BRInternationalPhone/BRInternationalPhone';
import { notify } from 'components/Notify/Notify';


import SIGN_UP_LANDING_IMAGE from 'assets/images/integration-sigupform-asset.png';
import SIGN_UP_LANDING_BOSTA_LOGO from 'assets/images/integration-signup-asset2.svg';
import SIGN_UP_LANDING_IMAGE_RTL from 'assets/images/integrations-signup-asset-ar.svg';
import SIGN_UP_LANDING_BOSTA_LOGO_RTL from 'assets/images/integrations-signup-asset2-ar.svg';

import './SignUpForm.less';

const SignUpForm = () => {
  const formRef = useRef();
  const { formatMessage , locale } = useIntl();
  const LANDING_IMAGE = locale === 'ar-EG' ? SIGN_UP_LANDING_IMAGE_RTL : SIGN_UP_LANDING_IMAGE;
  const LOGO = locale === 'ar-EG' ? SIGN_UP_LANDING_BOSTA_LOGO_RTL : SIGN_UP_LANDING_BOSTA_LOGO;
  const [isLoading, setIsLoading] = useState(false);
  const [country, setCountry] = useState(ALL_COUNTRIES[0]);
  const [usedPhoneNumber, setUsedPhoneNumber] = useState(false);

  const onSubmit = async (values = {}) => {
    setIsLoading(true);
    const { phone, ...restValues } = values;
    const payload = {
      ...restValues,
      phoneNumber : country.codeNumber + values.phone,
    };

    try {
      await integrationsFormSubmit(payload);

      notify({
        msg: formatMessage({ id: 'bosta_integration_landing.form.success' }),
        type: 'success'
      });

      formRef.current.resetFields();
    } catch (error) {
      notify({
        msg:
          error.message ||
          formatMessage({ id: 'bosta_integration_landing.form.failure' }),
        type: 'error'
      });
    }

    setIsLoading(false);
  };

  return (
    <div id="SignUpForm" className="br-landing-signup">
      <img
        className="br-landing-signup-image"
        src={LANDING_IMAGE}
        alt="signup"
      />
      <img
        className="br-landing-signup-logo"
        src={LOGO}
        alt="bosta-logo"
      />

      <h1>
        {formatMessage({ id: 'bosta_integration_landing.form.title' })}{' '}
        <span>
          {formatMessage({ id: 'bosta_integration_landing.form.span' })}
        </span>
      </h1>

      <div className="br-landing-signup-form">
        <h1>
          {formatMessage({ id: 'bosta_integration_landing.form.form-title' })}
        </h1>

        <Form onFinish={onSubmit} ref={formRef}>
          <Form.Item
            className="body-medium"
            label={formatMessage({ id: 'bosta_integration_landing.form.name' })}
            name="name"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            className="body-medium"
            label={formatMessage({
              id: 'bosta_integration_landing.form.business_name'
            })}
            name="businessName"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          <div className="br-landing-signup-form__phone">
            <BRInternationalPhone
              formRef={formRef}
              country={country}
              setCountry={setCountry}
              usedPhoneNumber={usedPhoneNumber}
              setUsedPhoneNumber={setUsedPhoneNumber}
              withSelect
              disabled={false}
            />
          </div>

          <Form.Item
            label={formatMessage({
              id: 'bosta_integration_landing.form.email'
            })}
            name="email"
            rules={[
              { required: true },
              emailRule(formatMessage({ id: 'form.email_not_valid' }))
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={formatMessage({ id: 'bosta_integration_landing.form.AMO' })}
            name="averageMonthlyOrders"
            rules={[{ required: true }]}
          >
            <Select options={AVERAGE_MONTHLY_ORDERS} />
          </Form.Item>

          <Form.Item
            label={formatMessage({
              id: 'bosta_integration_landing.form.IntWith'
            })}
            name="integrateWith"
            rules={[{ required: true }]}
          >
            <Select options={INTEGRATIONS_DATA} />
          </Form.Item>

          <Form.Item
            label={formatMessage({ id: 'bosta_fulfillment.form.ship_way' })}
            name="isWorkingWithBosta"
            rules={[{ required: true }]}
            initialValue={true}
          >
            <Radio.Group>
              <Radio value={true}>{formatMessage({ id: 'common.yes' })}</Radio>
              <Radio value={false}>{formatMessage({ id: 'common.no' })}</Radio>
            </Radio.Group>
          </Form.Item>

          <BRButton
            className="br-landing-submit-btn"
            label={formatMessage({
              id: 'bosta_integration_landing.form.button'
            })}
            type="destructive-primary"
            htmlType="submit"
            loading={isLoading}
          />
        </Form>
      </div>
    </div>
  );
};

export default SignUpForm;
