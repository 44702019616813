import React from 'react';
import { useIntl } from 'react-intl';
import { Collapse } from 'antd';
import { ReactComponent as Down } from 'assets/icons/DownArrowIcon.svg';
import { ReactComponent as UpArrow } from 'assets/icons/Chevron-up.svg';

import { COLLAPSE_DATA } from 'constants/integrations';

import './Collapse.less';

const { Panel } = Collapse;

const CollapseComponent = () => {
  const { formatMessage } = useIntl();

  const renderAnswer = (answer) => {
    const links = [
      {
        text: 'Bosta Shipping Plugin',
        url: 'https://apps.shopify.com/bosta-shipping-app'
      },
      {
        text: 'Bosta settings',
        url: 'https://business.bosta.co/settings/api-integration'
      },
      {
        text: 'Custom API Integration',
        url: 'https://docs.bosta.co/api/#/'
      },
      {
        text: 'ملحق بوسطة',
        url: 'https://apps.shopify.com/bosta-shipping-app'
      },
      {
        text: 'إعدادات بوسطة',
        url: 'https://business.bosta.co/settings/api-integration'
      }
    ];

    links.forEach(({ text, url }) => {
      const regex = new RegExp(text, 'gi');
      answer = answer.replace(
        regex,
        `<a href="${url}" target="_blank" rel="noopener noreferrer">${text}</a>`
      );
    });

    return <span dangerouslySetInnerHTML={{ __html: answer }} />;
  };

  return (
    <div className="br-collapse-container">
      <h1>{formatMessage({ id: 'bosta_integration_landing.faq.title' })}</h1>
      {COLLAPSE_DATA.map((item) => (
        <Collapse
          key={item.key}
          className="br-collapse-panel"
          collapsible="icon "
          expandIcon={({ isActive }) => (
            <span className="br-collapse-icon">
              {isActive ? <UpArrow /> : <UpArrow className='br-collapse-icon-down'/>}
            </span>
          )}
          expandIconPosition="right"
        >
          <Panel
            className="br-collapse-panel-item"
            header={item.question}
            key={item.key}
          >
            <p>{renderAnswer(item.answer)}</p>
          </Panel>
        </Collapse>
      ))}
    </div>
  );
};

export default CollapseComponent;
