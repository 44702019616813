import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Modal, Radio, Space } from 'antd';

import { getUserCurrency } from 'constants/countries/countries-mapping';
import { TRACKING_PAGE_PAYMOB_CARD_PAYMENT } from 'constants/shipments';
import { ONLINE_PAYMENT_METHODS } from 'constants/payment';
import { formatNumbersBasedOnLanguage } from 'utils/intl-wrapper';
import { requestToPayOnline } from 'services/shipments';

import { notify } from 'components/Notify/Notify';

import { ReactComponent as PayOnline } from 'assets/icons/payOnlineIcon.svg';

import './PayModal.less';

export default function PayModal({
  trackingDetails,
  codAmount,
  close,
  setOpen,
  token,
  ...props
}) {
  const { formatMessage } = useIntl();

  const [step, setStep] = useState(0);
  const [method, setMethod] = useState(ONLINE_PAYMENT_METHODS.CARD.value);
  const [iframeLink, setIframeLink] = useState(null);
  const [loading, setLoading] = useState(false);

  const { TrackingNumber: trackingNumber } = trackingDetails || {};

  const handleGoToNextStep = async () => {
    if (method === ONLINE_PAYMENT_METHODS.CARD.value) {
      await handlePay();
    }
    setStep((prev) => prev + 1);
  };

  const handlePay = async () => {
    setLoading(true);
    try {
      const payload = {
        trackingNumber: trackingNumber.trim(),
        paymentType: TRACKING_PAGE_PAYMOB_CARD_PAYMENT
      };
      const { result } = await requestToPayOnline({
        token,
        payload
      });
      setIframeLink(result?.iframeLink);
    } catch (error) {
      notify({ msg: error.message });
    } finally {
      setLoading(false);
    }
  };

  const handleChangeMethod = (e) => {
    setMethod(e.target.value);
  };

  const handleClose = () => {
    setOpen(false);
    close();
  };

  return (
    <Modal
      title={
        <>
          {formatMessage({
            id: `tracking.pay_modal.${step ? 'confirm_title' : 'title'}`
          })}
          {!!step && (
            <div className="body">
              {formatMessage({
                id: `tracking.pay_modal.subtitle_${method}`
              })}
            </div>
          )}
        </>
      }
      wrapClassName="br-pay-modal__wrapper"
      className="br-pay-modal"
      onCancel={handleClose}
      okText={formatMessage({ id: 'tracking.pay_modal.proceed' })}
      onOk={handleGoToNextStep}
      okButtonProps={{ loading }}
      {...(step && { footer: null })}
      {...props}
    >
      <div className="br-pay-modal__amount-section">
        <span className="heading">
          <PayOnline />
          {formatMessage({ id: 'tracking.pay_modal.amount_to_pay' })}
        </span>
        <span className="display-xs">
          {getUserCurrency(formatNumbersBasedOnLanguage(codAmount)).localized}
        </span>
      </div>
      {!!step && (
        <div className="br-tracking__payment-modal-container">
          {method === ONLINE_PAYMENT_METHODS.CARD.value ? (
            <iframe
              className="br-tracking__payment-modal-iframe"
              src={iframeLink}
            />
          ) : (
            <span>QR code for instapay</span> // temp
          )}
        </div>
      )}
      <div className="br-pay-modal__methods-section">
        {!step && (
          <div className="body-medium">
            {formatMessage({ id: 'tracking.pay_modal.select_method' })}
          </div>
        )}
        <div>
          {!step && (
            <Radio.Group
              onChange={handleChangeMethod}
              defaultValue={ONLINE_PAYMENT_METHODS.CARD.value}
            >
              <Space direction="vertical">
                {Object.values(ONLINE_PAYMENT_METHODS).map(
                  ({ value, label, icon, disabled = false }) => (
                    <Radio
                      value={value}
                      key={value}
                      disabled={disabled}
                      className="br-pay-modal-select-method-radio"
                    >
                      <span>{label}</span> <span>{icon}</span>
                    </Radio>
                  )
                )}
              </Space>
            </Radio.Group>
          )}
        </div>
      </div>
    </Modal>
  );
}
