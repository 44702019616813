import React from 'react';
import { useIntl } from 'react-intl';

import { HERO_STATS } from 'constants/integrations';

import LandingImageAR from 'assets/images/dashboard-landing-ar.png';
import LandingImageEN from 'assets/images/dashboard-landing.png';

import './Hero.less';

const Hero = () => {
  const { formatMessage, locale } = useIntl();
  const LandingImage = locale === 'ar-EG' ? LandingImageAR : LandingImageEN;

  const scrollToSignUp = () => {
    const element = document.getElementById('SignUpForm');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div className="br-hero-container">
      <div className="br-hero-container-left">
        <h1>
          {formatMessage({ id: 'bosta_integration_landing.hero.header' })}{' '}
          <span>
            {formatMessage({ id: 'bosta_integration_landing.hero.span' })}
          </span>
        </h1>
        <p>
          {formatMessage({
            id: 'bosta_integration_landing.hero.description_1'
          })}{' '}
          <span>
            {formatMessage({ id: 'bosta_integration_landing.hero.shopify' })}
          </span>
          ,{' '}
          <span>
            {formatMessage({
              id: 'bosta_integration_landing.hero.woocommerce'
            })}
          </span>
          {' '}
          <span>
            {formatMessage({ id: 'bosta_integration_landing.hero.magento' })}
          </span>
          ,{formatMessage({ id: 'bosta_integration_landing.hero.or' })}
          <span>
            {formatMessage({ id: 'bosta_integration_landing.hero.API' })}
          </span>{' '}
          {formatMessage({
            id: 'bosta_integration_landing.hero.description_2'
          })}
        </p>
        <button
          onClick={scrollToSignUp}
          className="br-hero-container-left-button"
        >
          {formatMessage({ id: 'bosta_integration_landing.hero.signup' })}
        </button>
        <div className="br-hero-container-left-stats">
          {HERO_STATS.map((stat, index) => (
            <div key={index} className="br-hero-container-left-stats-item">
              <span className="br-hero-container-left-stats-item-number">
                {stat.Number}
              </span>
              <span className="br-hero-container-left-stats-item-text">
                {stat.Text}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="br-hero-container-right">
        <img src={LandingImage} alt="landing-image" />
      </div>
    </div>
  );
};

export default Hero;
