import { useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { withRouter } from 'react-router-dom';
import { Layout } from 'antd';
import Icon from '@ant-design/icons';

import { LOCALE } from 'constants/intl-wrapper';
import { CUSTOM_API, PLUGINS } from 'constants/merged-integrations';
import { isContactUsAvailable, pathLang } from 'utils/helpers';
import { getLocaleFromLocalStorage } from 'utils/intl-wrapper';
import { HOST_NAME } from 'constants/helpers';

import { ReactComponent as Logo } from 'assets/icons/Bosta_Logo.svg';
import { ReactComponent as ArabicLogo } from 'assets/icons/Bosta_Logo_Arabic.svg';
import { ReactComponent as WhatsAppIcon } from 'assets/icons/whatsapp-icon.svg';

import './Footer.less';

const { WOOCOMMERCE, SHOPIFY } = PLUGINS;

const Footer = ({ history, intl }) => {
  const pathSegment = window.location.pathname.split('/');
  const isFooterHidden =
    pathSegment.includes('tracking-shipments') ||
    pathSegment.includes('contact-shipping-sales');

  const language = pathLang || getLocaleFromLocalStorage();

  const handleGoTo = useCallback(
    (url) => {
      history.push(url);
    },
    [history]
  );

  return isFooterHidden ? null : (
    <Layout.Footer className="br-container__footer">
      <Icon
        className="br-navbar__logo"
        component={intl.locale.includes(LOCALE.EN) ? Logo : ArabicLogo}
      />
      <div className="br-container__footer-links-container">
        <ul>
          <li> {intl.formatMessage({ id: 'navbar.products' })}</li>
          <li>
            <a href={`${HOST_NAME}/${language}/solutions`}>
              {intl.formatMessage({ id: 'navbar.solutions' })}
            </a>
          </li>
          <li>
            <a href={`${HOST_NAME}/${language}/mobile`}>
              {intl.formatMessage({ id: 'navbar.mobile_app' })}
            </a>
          </li>
          <li>
            <a href={`${HOST_NAME}/${language}/dashboard`}>
              {intl.formatMessage({ id: 'navbar.dashboard' })}
            </a>
          </li>
        </ul>
        <ul>
          <li>
            <a className='br-container__footer-links-container-integrations' href={`${HOST_NAME}/${language}/integrations`}>
              {intl.formatMessage({ id: 'navbar.footer_integrations' })}
            </a>
          </li>
        </ul>
        <ul>
          <li> {intl.formatMessage({ id: 'navbar.use_cases' })} </li>
          <li>
            <a href={`${HOST_NAME}/${language}/smes`}>
              {intl.formatMessage({ id: 'navbar.smes' })}
            </a>
          </li>
          <li>
            <a href={`${HOST_NAME}/${language}/business`}>
              {intl.formatMessage({ id: 'navbar.business' })}
            </a>
          </li>
        </ul>
        <ul>
          <li> {intl.formatMessage({ id: 'navbar.company' })} </li>
          <li>
            <a href={`${HOST_NAME}/${language}/about`}>
              {intl.formatMessage({ id: 'navbar.about_us' })}
            </a>
          </li>
          <li>
            <a href={`${HOST_NAME}/${language}/careers`}>
              {intl.formatMessage({ id: 'navbar.careers' })}
            </a>
          </li>
          <li>
            <a href={`${HOST_NAME}/${language}/pricing`}>
              {intl.formatMessage({ id: 'navbar.pricing' })}
            </a>
          </li>
          <li>
            <a href={`${HOST_NAME}/${language}/faq`}>
              {intl.formatMessage({ id: 'navbar.faq' })}
            </a>
          </li>
          {isContactUsAvailable() && (
            <>
              <li>
                <a href={`${HOST_NAME}/${language}/contact-shipping-sales`}>
                  {intl.formatMessage({ id: 'navbar.help' })}
                </a>
              </li>
              <li className="br-container__footer__contact">
                <a href="tel:+966115201626">+966115201626</a>
              </li>
              <li className="br-container__footer__contact-whatsapp">
                <a
                  href="https://wa.me/+966595531906"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <WhatsAppIcon />
                  {intl.formatMessage({ id: 'navbar.chat_whatsapp' })}
                </a>
              </li>
              <li>
                <a href="mailto:help.ksa@bosta.co"> help.ksa@bosta.co</a>
              </li>
            </>
          )}
        </ul>
        <ul>
          <li className="br-container__footer__track-shipment">
            <a href={`${HOST_NAME}/${language}/tracking-shipments`}>
              {intl.formatMessage({ id: 'navbar.track_shipment' })}
            </a>
          </li>
        </ul>
      </div>
    </Layout.Footer>
  );
};

export default withRouter(injectIntl(Footer));
