import React from 'react';

import Hero from './components/Hero/Hero.js';
import Features from './components/Features/Features';
import Services from './components/Services/Services';
import Steps from './components/Steps/Steps_Component';
import Collapse_Component from './components/Collapse/Collapse_Component';
import CarouselBarLanding from './components/CarouselBarLanding/CarouselBarLanding';
import SignUpForm from 'components/Landing/components/SignUp/SignUpForm';
import Banner from './components/Banner/Banner.js';

import './Integrations.less';

const Integrations = () => {
  return (
    <div>
      <Banner />
      <div className="br-landing__integration">
        <Hero />
        <Features />
        <Services />
        <Steps />
        <Collapse_Component />
        <CarouselBarLanding />
        <SignUpForm />
      </div>
    </div>
  );
};

export default Integrations;
