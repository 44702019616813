import { fmt } from 'components/IntlWrapper/IntlWrapper';
import { PLUGINS, CUSTOM_API } from './merged-integrations';
import { LOCALE } from 'constants/intl-wrapper';
import {
  isBostaInsightsAvailable,
  isBostaCapitalAvailable
} from './countries/countries-mapping';

const { WOOCOMMERCE, SHOPIFY } = PLUGINS;

export const MIDDLE_LINKS = [
  {
    title: fmt({ id: 'navbar.products' }),
    url: '/solutions',
    isMenu: true,
    menuItems: [
      { label: fmt({ id: 'navbar.solutions' }), link: '/solutions' },
      { label: fmt({ id: 'navbar.dashboard' }), link: '/dashboard' },
      { label: fmt({ id: 'navbar.mobile_app' }), link: '/mobile' },
      ...(isBostaCapitalAvailable()
        ? [
            {
              label: fmt({ id: 'navbar.bosta_capital' }),
              link: '/bosta-capital'
            }
          ]
        : []),
      { label: fmt({ id: 'navbar.bosta_fulfillment' }), link: '/fulfillment' }
    ]
  },
  {
    title: fmt({ id: 'navbar.integrations' }),
    url: '/integrations'
  },
  {
    title: fmt({ id: 'navbar.use_cases' }),
    isMenu: true,
    menuItems: [
      { label: fmt({ id: 'navbar.business' }), link: '/business' },
      { label: fmt({ id: 'navbar.smes' }), link: '/smes' }
    ]
  },
  { title: fmt({ id: 'navbar.pricing' }), url: '/pricing' },
  {
    title: fmt({ id: 'navbar.resources' }),
    url: '/blog',
    isMenu: true,
    menuItems: [
      {
        label: fmt({ id: 'navbar.blog' }),
        link: '/blog'
      },
      {
        label: fmt({ id: 'navbar.bosta_insights' }),
        link: '/insights',
        haveAccess: isBostaInsightsAvailable()
      }
    ]
  }
];

export const SIGN_IN_URL = 'https://business.bosta.co/signin';

export const getPageAnnouncement = () => {
  const pathName = window.location.pathname?.split('/')[2];
  switch (pathName) {
    case 'contact-shipping-sales':
      return {
        [LOCALE.EN]: false,
        [LOCALE.AR.toLowerCase()]: false,
        [LOCALE.EN_SA]: true,
        [LOCALE.AR_SA.toLowerCase()]: true,
        id: 'contact_shipping_sales',
        endDate: '2024-02-28'
      };

    default:
      return false;
  }
};
