import React from 'react';

import './Service.less';

const Service = ({ item }) => {
  return (
    <div className="br-service-container">
      <div className="br-service-icon-div">
        <item.Icon />
      </div>
      <h1 className='br-service-title'>{item.Title}</h1>
      <p className='br-service-desc'>{item.Desc}</p>
    </div>
  );
};

export default Service;
