import React from 'react';
import { useIntl } from 'react-intl';

import { SERVICES_DATA } from 'constants/integrations';

import Service from './components/Service';

import CloudVector from 'assets/images/cloud-up.svg';

import './Services.less';

const Services = () => {
  const { formatMessage } = useIntl();
  return (
    <div className="br-services-container">
      <img src={CloudVector} alt="cloud" className="br-services-cloud"></img>
      <img src={CloudVector} alt="cloud" className="br-services-cloud-bottom"></img>

      <h1>
        {formatMessage({ id: 'bosta_integration_landing.services.title' })}
      </h1>
      <p>
        {formatMessage({
          id: 'bosta_integration_landing.services.desc'
        })}
      </p>
      <div className="br-services-components">
        {SERVICES_DATA.map((item, index) => {
          return <Service item={item} />;
        })}
      </div>
    </div>
  );
};

export default Services;
