import React from 'react';
import { useIntl } from 'react-intl';

import { CAROUSEL_BAR_PARTNERS } from 'constants/integrations';

import './CarouselBarLanding.less';

const CarouselBarLanding = () => {
  const { formatMessage } = useIntl();

  return (
    <div className="br-CarouselBar-landing_section">
      <h1>
        {formatMessage({
          id: 'bosta_integration_landing.trusted_companies.title'
        })}{' '}
        <span>
          {formatMessage({
            id: 'bosta_integration_landing.trusted_companies.span'
          })}
        </span>
      </h1>
      <div className="br-CarouselBar-landing_logos">
        <div>
          {CAROUSEL_BAR_PARTNERS.map((item, index) => (
            <img key={index} alt="logo" src={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default CarouselBarLanding;
