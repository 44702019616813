import { fmt } from 'components/IntlWrapper/IntlWrapper';

import Emma from 'assets/images/emma-img.png';
import Taager from 'assets/images/taager-img.png';
import Noon from 'assets/images/noon-img.png';
import Amazon from 'assets/images/amazon-img.png';
import OolAmeme from 'assets/images/ool-ameme.jpg';
import BFSportsWear from 'assets/images/BF-Sportswear.jpg';
import Dejavu from 'assets/images/Dejavu.jpg';
import Essentials from 'assets/images/Essentials.png';
import JockeyJoy from 'assets/images/Jockey-Joy.jpg';
import Kenzz from 'assets/images/Kenzz.png';
import Khotwah from 'assets/images/Khotwah.jpg';
import Pepla from 'assets/images/Pepla.jpg';
import Snuggs from 'assets/images/Snuggs.jpg';
import SourceBeuty from 'assets/images/Source-beauty.png';
import WayUpSport from 'assets/images/Wayup-Sports.jpg';
import { ReactComponent as Clock } from 'assets/icons/clock_new.svg';
import { ReactComponent as CalendarIcon } from 'assets/icons/new-calendar.svg';
import { ReactComponent as PrinterIcon } from 'assets/icons/new-printer.svg';
import { ReactComponent as RouterIcon } from 'assets/icons/routing.svg';
import { ReactComponent as MoneyIcon } from 'assets/icons/moneys.svg';
import { ReactComponent as BoxIcon } from 'assets/icons/new-box.svg';
import ShopifyIcon from 'assets/images/Shopify Icon.png';
import WooCommerceIcon from 'assets/images/WooCommerce Icon.png';
import APIcon from 'assets/images/API integration.png';

export const ECOMMERCE_PLUGINS_CONTENT = [
  {
    title: fmt({
      id: 'integrations.ecommerce_plugins.content.secured_linking.title'
    }),
    subtitle: fmt({
      id: 'integrations.ecommerce_plugins.content.secured_linking.subtitle'
    })
  },
  {
    title: fmt({
      id: 'integrations.ecommerce_plugins.content.easy_tracking.title'
    }),
    subtitle: fmt({
      id: 'integrations.ecommerce_plugins.content.easy_tracking.subtitle'
    })
  },
  {
    title: fmt({
      id: 'integrations.ecommerce_plugins.content.quick_actions.title'
    }),
    subtitle: fmt({
      id: 'integrations.ecommerce_plugins.content.quick_actions.subtitle'
    })
  }
];

export const SMART_APIS_CONTENT = [
  {
    title: fmt({
      id: 'integrations.smart_apis.content.various_endpoints.title'
    }),
    subtitle: fmt({
      id: 'integrations.smart_apis.content.various_endpoints.subtitle'
    })
  },
  {
    title: fmt({
      id: 'integrations.smart_apis.content.real_time_tracking.title'
    }),
    subtitle: fmt({
      id: 'integrations.smart_apis.content.real_time_tracking.subtitle'
    })
  }
];

export const AVERAGE_MONTHLY_ORDERS = [
  {
    value: 'Less than 100',
    label: fmt({ id: 'bosta_integration_landing.form.orders_number.below_100' })
  },
  {
    value: '100 - 200',
    label: fmt({ id: 'bosta_integration_landing.form.orders_number.100_200' })
  },
  {
    value: '200 - 400',
    label: fmt({ id: 'bosta_integration_landing.form.orders_number.200_400' })
  },
  {
    value: '400 - 1,000',
    label: fmt({
      id: 'bosta_integration_landing.form.orders_number.400_1,000'
    })
  },
  {
    value: '1,000 or more',
    label: fmt({
      id: 'bosta_integration_landing.form.orders_number.1,000_or_more'
    })
  }
];
export const INTEGRATIONS_DATA = [
  {
    value: 'Shopify',
    label: fmt({ id: 'bosta_integration_landing.form.shopify' })
  },
  {
    value: 'WooCommerce',
    label: fmt({ id: 'bosta_integration_landing.form.woocommerce' })
  },
  {
    value: 'APIs',
    label: fmt({ id: 'bosta_integration_landing.form.custom' })
  }
];
export const FEATURES_DATA = [
  {
    Name: fmt({
      id: 'bosta_integration_landing.features.feature_shopify.Name'
    }),
    Image: ShopifyIcon,
    Desc: fmt({
      id: 'bosta_integration_landing.features.feature_shopify.Desc'
    }),
    Features: [
      fmt({
        id: 'bosta_integration_landing.features.feature_shopify.feats.feat1'
      }),
      fmt({
        id: 'bosta_integration_landing.features.feature_shopify.feats.feat2'
      }),
      fmt({
        id: 'bosta_integration_landing.features.feature_shopify.feats.feat3'
      }),
      fmt({
        id: 'bosta_integration_landing.features.feature_shopify.feats.feat4'
      }),
      fmt({
        id: 'bosta_integration_landing.features.feature_shopify.feats.feat5'
      })
    ]
  },
  {
    Name: fmt({
      id: 'bosta_integration_landing.features.feature_woocommerce.Name'
    }),
    Image: WooCommerceIcon,
    Desc: fmt({
      id: 'bosta_integration_landing.features.feature_woocommerce.Desc'
    }),
    Features: [
      fmt({
        id: 'bosta_integration_landing.features.feature_woocommerce.feats.feat1'
      }),
      fmt({
        id: 'bosta_integration_landing.features.feature_woocommerce.feats.feat2'
      }),
      fmt({
        id: 'bosta_integration_landing.features.feature_woocommerce.feats.feat3'
      }),
      fmt({
        id: 'bosta_integration_landing.features.feature_woocommerce.feats.feat4'
      })
    ]
  },
  {
    Name: fmt({
      id: 'bosta_integration_landing.features.feature_api.Name'
    }),
    Image: APIcon,
    Desc: fmt({
      id: 'bosta_integration_landing.features.feature_api.Desc'
    }),
    Features: [
      fmt({
        id: 'bosta_integration_landing.features.feature_api.feats.feat1'
      }),
      fmt({
        id: 'bosta_integration_landing.features.feature_api.feats.feat2'
      }),
      fmt({
        id: 'bosta_integration_landing.features.feature_api.feats.feat3'
      }),
      fmt({
        id: 'bosta_integration_landing.features.feature_api.feats.feat4'
      })
    ]
  }
];

export const HERO_STATS = [
  {
    Number: fmt({ id: 'bosta_integration_landing.hero.stats.35' }),
    Text: fmt({ id: 'bosta_integration_landing.hero.stats.PD' })
  },
  {
    Number: fmt({ id: 'bosta_integration_landing.hero.stats.1M' }),
    Text: fmt({ id: 'bosta_integration_landing.hero.stats.MD' })
  },
  {
    Number: fmt({ id: 'bosta_integration_landing.hero.stats.37' }),
    Text: fmt({ id: 'bosta_integration_landing.hero.stats.SP' })
  }
];
export const SERVICES_DATA = [
  {
    Icon: Clock,
    Title: fmt({ id: 'bosta_integration_landing.services.service1.title' }),
    Desc: fmt({ id: 'bosta_integration_landing.services.service1.desc' })
  },
  {
    Icon: CalendarIcon,
    Title: fmt({ id: 'bosta_integration_landing.services.service2.title' }),
    Desc: fmt({ id: 'bosta_integration_landing.services.service2.desc' })
  },
  {
    Icon: PrinterIcon,
    Title: fmt({ id: 'bosta_integration_landing.services.service3.title' }),
    Desc: fmt({ id: 'bosta_integration_landing.services.service3.desc' })
  },
  {
    Icon: RouterIcon,
    Title: fmt({ id: 'bosta_integration_landing.services.service4.title' }),
    Desc: fmt({ id: 'bosta_integration_landing.services.service4.desc' })
  },
  {
    Icon: MoneyIcon,
    Title: fmt({ id: 'bosta_integration_landing.services.service5.title' }),
    Desc: fmt({ id: 'bosta_integration_landing.services.service5.desc' })
  },
  {
    Icon: BoxIcon,
    Title: fmt({ id: 'bosta_integration_landing.services.service6.title' }),
    Desc: fmt({ id: 'bosta_integration_landing.services.service6.desc' })
  }
];
export const CAROUSEL_BAR_PARTNERS = [
  Emma,
  Amazon,
  Taager,
  Noon,
  OolAmeme,
  BFSportsWear,
  Dejavu,
  Essentials,
  JockeyJoy,
  Kenzz,
  Khotwah,
  Pepla,
  Snuggs,
  SourceBeuty,
  WayUpSport,
  Emma,
  Amazon,
  Taager,
  Noon,
  OolAmeme,
  BFSportsWear,
  Dejavu,
  Essentials,
  JockeyJoy,
  Kenzz,
  Khotwah,
  Pepla,
  Snuggs,
  SourceBeuty,
  WayUpSport
];

export const COLLAPSE_DATA = [
  {
    key: '1',
    question: fmt(
      {
        id: 'bosta_integration_landing.faq.q1.question'
      },
      {
        span: (children) => (
          <a href="https://apps.shopify.com/bosta-shipping-app">{children}</a>
        )
      }
    ),
    answer: fmt({
      id: 'bosta_integration_landing.faq.q1.answer'
    })
  },
  {
    key: '2',
    question: fmt({
      id: 'bosta_integration_landing.faq.q2.question'
    }),
    answer: fmt({
      id: 'bosta_integration_landing.faq.q2.answer'
    })
  },
  {
    key: '3',
    question: fmt({
      id: 'bosta_integration_landing.faq.q3.question'
    }),
    answer: fmt({
      id: 'bosta_integration_landing.faq.q3.answer'
    })
  },
  {
    key: '4',
    question: fmt({
      id: 'bosta_integration_landing.faq.q4.question'
    }),
    answer: fmt({
      id: 'bosta_integration_landing.faq.q4.answer'
    })
  },
  {
    key: '5',
    question: fmt({
      id: 'bosta_integration_landing.faq.q5.question'
    }),
    answer: fmt({
      id: 'bosta_integration_landing.faq.q5.answer'
    })
  },
  {
    key: '6',
    question: fmt({
      id: 'bosta_integration_landing.faq.q6.question'
    }),
    answer: fmt({
      id: 'bosta_integration_landing.faq.q6.answer'
    })
  },
  {
    key: '7',
    question: fmt({
      id: 'bosta_integration_landing.faq.q7.question'
    }),
    answer: fmt({
      id: 'bosta_integration_landing.faq.q7.answer'
    })
  }
];

export const STEPS_LANDING = [
  {
    title: (
      <span>{fmt({ id: 'bosta_integration_landing.steps.step1.title' })}</span>
    ),
    icon: <span className="step-number_vertical">1</span>
  },
  {
    title: (
      <span>{fmt({ id: 'bosta_integration_landing.steps.step2.title' })}</span>
    ),
    icon: <span className="step-number_vertical">2</span>
  },
  {
    description: (
      <span>{fmt({ id: 'bosta_integration_landing.steps.step3.title' })}</span>
    ),
    icon: <span className="step-number_vertical">3</span>
  }
];
