import React from 'react';

import { ReactComponent as Check } from 'assets/icons/Check.svg';

import './Feature.less';
const Feature = ({ feature }) => {
  return (
    <div className="br-feature-container">
      <h1>{feature.Name}</h1>
      <div className="br-image-container">
        <img src={feature.Image} alt="Feature Image"></img>
      </div>
      <p>{feature.Desc}</p>
      <div className="br-feature-list">
        {feature.Features.map((item, index) => {
          return (
            <div className="br-feature-list-item">
              <Check />
              <span>{item}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Feature;
